import {
  DailyMachineReport,
  ICampaignJsonDetailReport,
  ICampaignReport,
  MachineDetailedValues,
  MachinesMeasurementValue,
  MachinesOperatingValue,
  OperatingAnalysisList,
  PaginatedMachines,
} from '@app/shared/models/analytics.model';
import { ComponentType, ImplementType, MachineBase, Machine as MachineModel, MachineType } from '@app/shared/models/machines.model';
import { Action } from '@ngrx/store';
import { StateError } from '..';

export const MACHINES_LIST = '[Analytics] Machines List';
export const MACHINES_LIST_SUCCESS = '[Analytics] Machines List Success';
export const MACHINES_LIST_FAILURE = '[Analytics] Machines List Failure';
export const MACHINES_LIST_COMPLETE = '[Analytics] Machines List Complete';

export const MEASUREMENT_VALUES = '[Analytics] Machines Measurement Values';
export const MEASUREMENT_VALUES_SUCCESS = '[Analytics] Machines Measurement Values Success';
export const MEASUREMENT_VALUES_FAILURE = '[Analytics] Machines Measurement Values Failure';
export const MEASUREMENT_VALUES_COMPLETE = '[Analytics] Machines Measurement Values Complete';

export const OPERATING_VALUES = '[Analytics] Machines Operating Values';
export const OPERATING_VALUES_SUCCESS = '[Analytics] Machines Operating Values Success';
export const OPERATING_VALUES_FAILURE = '[Analytics] Machines Operating Values Failure';
export const OPERATING_VALUES_COMPLETE = '[Analytics] Machines Operating Values Complete';

export const MACHINE = '[Analytics] Machine';
export const MACHINE_SUCCESS = '[Analytics] Machine Success';
export const MACHINE_FAILURE = '[Analytics] Machine Failure';
export const MACHINE_COMPLETE = '[Analytics] Machine Complete';

export const OPERATING_ANALYSIS = '[Analytics] Operating Analysis';
export const OPERATING_ANALYSIS_SUCCESS = '[Analytics] Operating Analysis Success';
export const OPERATING_ANALYSIS_FAILURE = '[Analytics] Operating Analysis Failure';
export const OPERATING_ANALYSIS_COMPLETE = '[Analytics] Operating Analysis Complete';

export const DETAILED_VALUES = '[Analytics] Detailed Values';
export const DETAILED_VALUES_SUCCESS = '[Analytics] Detailed Values Success';
export const DETAILED_VALUES_FAILURE = '[Analytics] Detailed Values Failure';
export const DETAILED_VALUES_COMPLETE = '[Analytics] Detailed Values Complete';

export const PAGINATED_MACHINES = '[Analytics] Paginated Machines';
export const PAGINATED_MACHINES_SUCCESS = '[Analytics] Paginated Machines Success';
export const PAGINATED_MACHINES_FAILURE = '[Analytics] Paginated Machines Failure';
export const PAGINATED_MACHINES_COMPLETE = '[Analytics] Paginated Machines Complete';

export const GET_CAMPAIGN_REPORT = '[Analytics] Get Campaign Report';
export const GET_CAMPAIGN_REPORT_SUCCESS = '[Analytics] Get Campaign Report Success';
export const GET_CAMPAIGN_REPORT_FAILURE = '[Analytics] Get Campaign Report Failure';
export const GET_CAMPAIGN_REPORT_COMPLETE = '[Analytics] Get Campaign Report Complete';

export const GET_JSON_CAMPAIGN_REPORT = '[Analytics] Get JSON Campaign Report';
export const GET_JSON_CAMPAIGN_REPORT_SUCCESS = '[Analytics] Get JSON Campaign Report Success';
export const GET_JSON_CAMPAIGN_REPORT_FAILURE = '[Analytics] Get JSON Campaign Report Failure';
export const GET_JSON_CAMPAIGN_REPORT_COMPLETE = '[Analytics] Get JSON Campaign Report Complete';

export const SET_CURRENT_DAILY_REPORT = '[Analytics] Set Current Daily Report';
export const RESET_ANALYTICS_DETAILS_DATA = '[Analytics] Reset Analytics Details Data';

export const RESET_ANALYTICS = '[Analytics] Reset Analytics';

export class MachinesList implements Action {
  readonly type = MACHINES_LIST;
  // constructor(public payload: { ids: string[]; timeZone: string }) {}
}

export class MachinesListSuccess implements Action {
  readonly type = MACHINES_LIST_SUCCESS;
  constructor(public payload: MachineModel[]) {}
}

export class MachinesListFailure implements Action {
  readonly type = MACHINES_LIST_FAILURE;
  constructor(public payload: StateError) {}
}

export class MachinesListComplete implements Action {
  readonly type = MACHINES_LIST_COMPLETE;
}

export class MeasurementValues implements Action {
  readonly type = MEASUREMENT_VALUES;
  constructor(public payload: { ids: string[]; timeZone: string }) {}
}

export class MeasurementValuesSuccess implements Action {
  readonly type = MEASUREMENT_VALUES_SUCCESS;
  constructor(public payload: MachinesMeasurementValue[]) {}
}

export class MeasurementValuesFailure implements Action {
  readonly type = MEASUREMENT_VALUES_FAILURE;
  constructor(public payload: StateError) {}
}

export class MeasurementValuesComplete implements Action {
  readonly type = MEASUREMENT_VALUES_COMPLETE;
}

export class OperatingValues implements Action {
  readonly type = OPERATING_VALUES;
  constructor(public payload: { ids: string[]; timeZone: string; widgetid?: string }) {}
}

export class OperatingValuesSuccess implements Action {
  readonly type = OPERATING_VALUES_SUCCESS;
  constructor(public payload: MachinesOperatingValue[]) {}
}

export class OperatingValuesFailure implements Action {
  readonly type = OPERATING_VALUES_FAILURE;
  constructor(public payload: StateError) {}
}

export class OperatingValuesComplete implements Action {
  readonly type = OPERATING_VALUES_COMPLETE;
}

export class Machine implements Action {
  readonly type = MACHINE;
  constructor(public payload: { id: string; type: MachineType | ComponentType | ImplementType }) {}
}

export class MachineSuccess implements Action {
  readonly type = MACHINE_SUCCESS;
  constructor(public payload: MachineBase) {}
}

export class MachineFailure implements Action {
  readonly type = MACHINE_FAILURE;
  constructor(public payload: StateError) {}
}

export class MachineComplete implements Action {
  readonly type = MACHINE_COMPLETE;
}

export class OperatingAnalysis implements Action {
  readonly type = OPERATING_ANALYSIS;
  constructor(
    public payload: {
      uuid: string;
      timeZone: string;
      measuredFromLocalDate: string;
      measuredUntilLocalDate: string;
      calculateEngineOffDuration?: boolean;
    }
  ) {}
}

export class OperatingAnalysisSuccess implements Action {
  readonly type = OPERATING_ANALYSIS_SUCCESS;
  constructor(public payload: OperatingAnalysisList) {}
}

export class OperatingAnalysisFailure implements Action {
  readonly type = OPERATING_ANALYSIS_FAILURE;
  constructor(public payload: StateError) {}
}

export class OperatingAnalysisComplete implements Action {
  readonly type = OPERATING_ANALYSIS_COMPLETE;
}

export class DetailedValues implements Action {
  readonly type = DETAILED_VALUES;
  constructor(
    public payload: {
      uuid: string;
      timeZone: string;
      measuredFromLocalDate: string;
      measuredUntilLocalDate: string;
    }
  ) {}
}

export class DetailedValuesSuccess implements Action {
  readonly type = DETAILED_VALUES_SUCCESS;
  constructor(public payload: MachineDetailedValues) {}
}

export class DetailedValuesFailure implements Action {
  readonly type = DETAILED_VALUES_FAILURE;
  constructor(public payload: StateError) {}
}

export class DetailedValuesComplete implements Action {
  readonly type = DETAILED_VALUES_COMPLETE;
}

// Paginated Machines
export class PaginatedMachinesList implements Action {
  readonly type = PAGINATED_MACHINES;
  constructor(public payload: { page: number; size: number; characteristic: string; machineIds: string; archived: boolean }) {}
}
export class PaginatedMachinesListSuccess implements Action {
  readonly type = PAGINATED_MACHINES_SUCCESS;
  constructor(public payload: PaginatedMachines) {}
}
export class PaginatedMachinesListFailure implements Action {
  readonly type = PAGINATED_MACHINES_FAILURE;
  constructor(public payload: StateError) {}
}
export class PaginatedMachinesListComplete implements Action {
  readonly type = PAGINATED_MACHINES_COMPLETE;
}

// Campaign Report
export class GetJsonCampaignReport implements Action {
  readonly type = GET_JSON_CAMPAIGN_REPORT;

  constructor(public campaignReportId: string) {}
}

export class GetJsonCampaignReportSuccess implements Action {
  readonly type = GET_JSON_CAMPAIGN_REPORT_SUCCESS;

  constructor(public campaignReportId: string, public campaignJsonDetailReport: ICampaignJsonDetailReport[]) {}
}

export class GetJsonCampaignReportFailure implements Action {
  readonly type = GET_JSON_CAMPAIGN_REPORT_FAILURE;

  constructor(public payload: StateError) {}
}

export class GetJsonCampaignReportComplete implements Action {
  readonly type = GET_JSON_CAMPAIGN_REPORT_COMPLETE;
}

export class GetCampaignReport implements Action {
  readonly type = GET_CAMPAIGN_REPORT;

  constructor(public campaignReportId: string) {}
}

export class GetCampaignReportSuccess implements Action {
  readonly type = GET_CAMPAIGN_REPORT_SUCCESS;

  constructor(public campaignReport: ICampaignReport) {}
}

export class GetCampaignReportFailure implements Action {
  readonly type = GET_CAMPAIGN_REPORT_FAILURE;

  constructor(public payload: StateError) {}
}

export class GetCampaignReportComplete implements Action {
  readonly type = GET_CAMPAIGN_REPORT_COMPLETE;
}

export class ResetAnalyticsDetailsData implements Action {
  readonly type = RESET_ANALYTICS_DETAILS_DATA;
}

export class ResetAnalytics implements Action {
  readonly type = RESET_ANALYTICS;
}

// Daily Report
export class SetCurrentDailyReport implements Action {
  readonly type = SET_CURRENT_DAILY_REPORT;

  constructor(public dailyReport: DailyMachineReport) {}
}

export type Actions =
  | MachinesList
  | MachinesListSuccess
  | MachinesListFailure
  | MachinesListComplete
  | MeasurementValues
  | MeasurementValuesSuccess
  | MeasurementValuesFailure
  | MeasurementValuesComplete
  | OperatingValues
  | OperatingValuesSuccess
  | OperatingValuesFailure
  | OperatingValuesComplete
  | Machine
  | MachineSuccess
  | MachineFailure
  | MachineComplete
  | OperatingAnalysis
  | OperatingAnalysisSuccess
  | OperatingAnalysisFailure
  | OperatingAnalysisComplete
  | DetailedValues
  | DetailedValuesSuccess
  | DetailedValuesFailure
  | DetailedValuesComplete
  | PaginatedMachinesList
  | PaginatedMachinesListSuccess
  | PaginatedMachinesListFailure
  | PaginatedMachinesListComplete
  | GetJsonCampaignReport
  | GetJsonCampaignReportSuccess
  | GetJsonCampaignReportFailure
  | GetJsonCampaignReportComplete
  | GetCampaignReport
  | GetCampaignReportSuccess
  | GetCampaignReportFailure
  | GetCampaignReportComplete
  | SetCurrentDailyReport
  | ResetAnalyticsDetailsData
  | ResetAnalytics;
